import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Noticia } from '../../model/Noticia';
import { NoticiasService } from '../noticias.service';

@Component({
  selector: 'app-mural-noticias',
  templateUrl: './mural-noticias.component.html',
  styleUrls: ['./mural-noticias.component.css']
})
export class MuralNoticiasComponent implements OnInit {

  noticias: Noticia[] = [];
  API_FOTO = this.noticiasService.getAPI() + 'fotos/'; 
  // API_FOTO = 'http://www.pmsaraguaia.pa.gov.br/transparencia/fotos/';
  filtro: string = '';
  noticiaSelecionada: Noticia;

  constructor(
      private noticiasService: NoticiasService,
      private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.noticiasService
      .listarTodasNoticias()
      .subscribe(noticias => {
        this.noticias = noticias;
        this.noticias.forEach(noticia => {
          noticia.fotoDestaque = this.API_FOTO + noticia.fotoDestaque;
        });
      });
  }

  onSelect(noticia: Noticia) {
    this.noticiaSelecionada = noticia;
    console.log(this.noticiaSelecionada);
  }

}
